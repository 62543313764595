import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import { ExecutiveFunctionResume } from "./ExecutiveFunctionResume";
import GetRequest from "../../../apiConnection/getRequest";
import PostRequest from "../../../apiConnection/postRequest";
import DisplayWord from "./ExecutiveFunction.json";

export const ExecutiveFunctionIndex = (props) => {

    const [t, i18n] = useTranslation("common");
    const component_id = props?.match?.params?.component_id;
    const repeat_time = props?.match?.params?.repeat_time;
    const day_sequence = props?.match?.params?.day_sequence;
    const activity_id = props?.match?.params?.activity_id;
    const history = useHistory();
    const [free, setFree] = useState(props?.match?.params?.free);

    const [word, setWord] = useState();
    const [color, setColor] = useState();
    const [colors, setColors] = useState([]);
    const [words, setWords] = useState([]);


    // const [totalSteps, setTotalSteps] = useState(10);
    const [score, setScore] = useState(0);

    const [wordColor, setWordColor] = useState("Red");
    // const [count, setCount] = useState(0);

    const [stepTimeRemaining, setStepTimeRemaining] = useState(0);

    const [timeRemaining, setTimeRemaining] = useState();
    const [activityPlayTime, setActivityPlayTime] = useState();

    const [round, setRound] = useState(0);
    const [avgAccuracy, setAvgAccuracy] = useState(0);
    const [accuracyCurrentRound, setAccuracyCurrentRound] = useState(0);
    const [maxSteps, setMaxSteps] = useState(10);
    const [step, setStep] = useState(1);
    const [isPaused, setIsPaused] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [activityId, setActivityId] = useState();
    const [totalTime, setTotalTime] = useState();
    const [activityStatus, setActivityStatus] = useState("");

    const [congruentResponseTimes, setCongruentResponseTimes] = useState([]);
    const [incongruentResponseTimes, setIncongruentResponseTimes] = useState([]);
    const [neutralResponseTimes, setNeutralResponseTimes] = useState([]);

    const [congruentAvgResponseTimes, setCongruentAvgResponseTimes] = useState([]);
    const [incongruentAvgResponseTimes, setIncongruentAvgResponseTimes] = useState([]);
    const [neutralAvgResponseTimes, setNeutralAvgResponseTimes] = useState([]);

    const [totalAvgCongruentResponseTime, setTotalAvgCongruentResponseTime] = useState(0);
    const [totalAvgIncongruentResponseTime, setTotalAvgIncongruentResponseTime] = useState(0);
    const [totalAvgNeutralResponseTime, setTotalAvgNeutralResponseTime] = useState(0);

    const [totalAvgResponseTime, setTotalAvgResponseTime] = useState(0);
    const [startTime, setStartTime] = useState();
    const [condition, setCondition] = useState();
    const [theme, setTheme] = useState(JSON.parse(localStorage.getItem("theme")));


    useEffect(() => {
        document.body.className = (theme) ? theme : 'light';
    }, [theme])

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    useEffect(() => {
        let timerId;

        // if (stepTimeRemaining < 100) {
        //     timerId = setInterval(() => {
        //         setStepTimeRemaining((prevTime) => prevTime + 1);
        //     }, 30);
        // }

        // calculation of time

        // milliSecond   width
        //  2000         100%
        //  2000/100     1%
        //  20           1%
        //  500          25%

        // if (stepTimeRemaining < 100) {
        timerId = setInterval(() => {
            setStepTimeRemaining((prevTime) => prevTime + 25);
        }, 700);
        // }

        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, [stepTimeRemaining]);


    useEffect(() => {
        setColors(["#BD10E0", "#019875", "#1788DA", "#FFD710"]);
        setWords(["violet", "green", "blue", "yellow"]);
    }, [])

    const change = () => {
        setStepTimeRemaining(0);
        // changeWord();
        // changeColor();
        changeDisplayWord();
    }

    const changeWord = () => {
        let displayWords = ["Lion", "Sky", "Ocean", "Bike", "Tree", "Flower", "Lake", "Forest", "Telescope"]
        setWord(displayWords[(Math.floor(Math.random() * displayWords.length))]);
    }

    const changeColor = () => {
        let displayWordColor = ["Red", "Green", "Blue", "Black"]
        setWordColor(displayWordColor[(Math.floor(Math.random() * displayWordColor.length))]);
    }


    const changeDisplayWord = () => {
        let wordObj = DisplayWord[(Math.floor(Math.random() * DisplayWord.length))];
        setWord(wordObj.word);
        setWordColor(wordObj.color);
        setCondition(wordObj.condition);
    }

    // useEffect(() => {
    //     changeDisplayWord();
    // }, [])


    useEffect(() => {
        change();
        setStartTime(Date.now());
    }, [step])


    useEffect(() => {
        if ((step <= maxSteps) && !isPaused) {
            const interval = setInterval(() => {
                // setCount(count + 1);
                setStep(step + 1);
                // setTotalSteps(totalSteps - 1);
                change();
            }, 3500);
            return () => clearInterval(interval);
        }
    }, [step, isPaused]);



    const colorCheck = (colorClick, wordColor, condition) => {
// console.log("colorCheck function called")
        // alert(colorClick, wordColor, condition)
        if (step <= maxSteps) {
            if (colorClick == wordColor) {
                // console.log("score incre")
                setScore(score + 1);

                const endTime = Date.now();
                var responseTime = endTime - startTime; // Calculate response time
                if (condition === 'congruent') {

                    setCongruentResponseTimes([...congruentResponseTimes, responseTime]);
                }
                if (condition === 'incongruent') {
                    setIncongruentResponseTimes([...incongruentResponseTimes, responseTime]);
                }
                if (condition === 'neutral') {
                    setNeutralResponseTimes([...neutralResponseTimes, responseTime]);
                }
            }
            setStep(step + 1);
        }
    }


    // ----------------------------------------------------------------------------------------------
    // Below are reponse times of current  round of all different condition
    useEffect(() => {
        if (congruentResponseTimes.length > 0) {
        setCongruentAvgResponseTimes((
            congruentResponseTimes.reduce((acc, curr) => acc + curr, 0) / congruentResponseTimes.length
        ).toFixed(0))
        } else {
            setCongruentAvgResponseTimes(0);
        }
    }, [congruentResponseTimes]);
    
    useEffect(() => {
        if(incongruentResponseTimes.length>0){
        setIncongruentAvgResponseTimes((
            incongruentResponseTimes.reduce((acc, curr) => acc + curr, 0) / incongruentResponseTimes.length
        ).toFixed(0));
    }else{
        setIncongruentAvgResponseTimes(0);
    }
    }, [incongruentResponseTimes])

    useEffect(() => {
        if(neutralResponseTimes.length>0){
        setNeutralAvgResponseTimes((
            neutralResponseTimes.reduce((acc, curr) => acc + curr, 0) / neutralResponseTimes.length
        ).toFixed(0));
    }else{
        setNeutralAvgResponseTimes(0);
    }
    }, [neutralResponseTimes])

    // ------------------------------------------------------------------------------------------------------
    // Below are reponse times of all  rounds of all different condition
    // useEffect(() => {
    //     setTotalAvgCongruentResponseTime(((totalAvgCongruentResponseTime * round) + (parseInt(congruentAvgResponseTimes.length))) / (round + 1));
    //     console.log('totalAvgCongruentResponseTime ',totalAvgCongruentResponseTime , ' round ',round, '  congruentAvgResponseTimes length', congruentAvgResponseTimes.length);
    // }, [congruentAvgResponseTimes])

useEffect(() => {
    // Check if congruentAvgResponseTimes is an array and not empty
    if (congruentAvgResponseTimes.length > 0) {
        setTotalAvgCongruentResponseTime(((totalAvgCongruentResponseTime * round) + (parseInt(congruentAvgResponseTimes))) / (round + 1));
        // console.log('totalAvgCongruentResponseTime ',totalAvgCongruentResponseTime , ' round ',round, '  congruentAvgResponseTimes length', congruentAvgResponseTimes.length);
    } else {
        setTotalAvgCongruentResponseTime(0)
    }
}, [congruentAvgResponseTimes, round]);

useEffect(() => {
        if(incongruentAvgResponseTimes.length>0){
        setTotalAvgIncongruentResponseTime(((totalAvgIncongruentResponseTime * round) + (parseInt(incongruentAvgResponseTimes))) / (round + 1));  
    }else{
            setTotalAvgIncongruentResponseTime(0);
        }
    }, [incongruentAvgResponseTimes])

    useEffect(() => {
        if(neutralAvgResponseTimes.length>0){
        setTotalAvgNeutralResponseTime(((totalAvgNeutralResponseTime * round) + (parseInt(neutralAvgResponseTimes))) / (round + 1));
        }else{
            setTotalAvgNeutralResponseTime(0) 
        }
    }, [neutralAvgResponseTimes])
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------
    useEffect(() => {
        setTotalAvgResponseTime(Math.round((parseInt(totalAvgCongruentResponseTime) + parseInt(totalAvgIncongruentResponseTime) + parseInt(totalAvgNeutralResponseTime)) / 3))
        // console.log("totalAvgResponseTime", totalAvgResponseTime)
    }, [totalAvgCongruentResponseTime, totalAvgIncongruentResponseTime, totalAvgNeutralResponseTime])
    // -------------------------------------------------------------

    useEffect(() => {
        // console.log("score",score)
        setAccuracyCurrentRound((score / maxSteps));
    }, [score])

    useEffect(() => {
        // console.log("accuracyCurrentRound",accuracyCurrentRound);
        // console.log("step",step)
        avgScore();
    }, [accuracyCurrentRound])




    const avgScore = () => {
        let accuracy;
        // if (step <= maxSteps){
            // console.log("last step acccuracy",avgAccuracy,"current step when clicked", step);
           
            accuracy = (((avgAccuracy * round) + accuracyCurrentRound) / (round + 1))
            setAvgAccuracy(accuracy)
        // }     
    }
    const resetRound = () => {
        setStep(0);
        setScore(0)
        setRound(round + 1);
        setAccuracyCurrentRound(0);
        getReactionSpeedUserData();
    }

    useEffect(() => {
        if (!free) {
            getReactionSpeedUserData()
        }
    }, [])


    const getReactionSpeedUserData = async () => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );

            if (refreshToken) {
                const getUserActivityData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_ACTIVITY_STATUS}?repeat_time=${repeat_time}&component_id=${component_id}&day_sequence=${day_sequence}&activity_id=${activity_id}`,
                    "",
                    "",
                    ""
                );

                if (getUserActivityData && getUserActivityData.status === 200) {
                    setIsLoading(false);
                    if (getUserActivityData?.data?.data?.activity_data) {
                        let dataObj = getUserActivityData?.data?.data?.activity_data;
                        setActivityId(dataObj.activity_id);
                        setActivityPlayTime(dataObj.activity_play_time);
                        setTotalTime(dataObj.activity_total_data[0].activity_duration);
                        setTimeRemaining(((dataObj.activity_total_data[0].activity_duration) * 60) - (dataObj.activity_play_time));
                        setRound(dataObj.activity_round_no);
                        setAvgAccuracy(dataObj.activity_score);
                        setActivityStatus(dataObj.status);
                        setTotalAvgCongruentResponseTime(dataObj.total_avg_congruent_response_time);
                        setTotalAvgIncongruentResponseTime(dataObj.total_avg_incongruent_response_time);
                        setTotalAvgNeutralResponseTime(dataObj.total_avg_neutral_response_time);
                        setTotalAvgResponseTime(dataObj.total_avg_response_time);
                        // setTotalAvgResponseTime(0);
                    }
                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }

    useEffect(() => {
        let timerId;

        if (!isPaused && timeRemaining > 0) {
            timerId = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000);
        }

        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, [timeRemaining, isPaused]);

    const togglePause = () => {
        setIsPaused(!isPaused);
    };

    const startTimer = () => {
        setIsPaused(false);
    }

    // const minutes = Math.floor(timeRemaining / 60);
    // const seconds = timeRemaining % 60;

    // var step_1 = 9
    useEffect(() => {
        setMaxSteps(Math.floor(Math.random() * (32 - 25 + 1)) + 25);
    }, [])

    useEffect(() => {
        if (activityStatus === "complete" && component_id && repeat_time && day_sequence && activity_id && !free) {
            history.push(`/executive-function-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`);
        }
        else if (activityStatus === "complete" && component_id && repeat_time && day_sequence && activity_id && free) {
            history.push(`/executive-function-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`);
        }
    }, [activityStatus])

 
    return (
        <>
            {step <= maxSteps ?
                <section className="reaction-speed-section executive-function-section-mob">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="stepsin stpelog">
                                <Link to="/dashboard">
                                    <img src={logo} className="logo-steps" alt="logo" />
                                </Link>
                            </div>
                            <div className="btnNext-top">
                                {free ?
                                    <Link to={`/executive-function-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`}>
                                        <button
                                            className="btn btn-primary  btnNext"
                                        >
                                            {t("user.activity.reaction_speed.back")}
                                        </button>
                                    </Link>
                                    : <Link to={`/executive-function-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}>
                                        <button
                                            className="btn btn-primary  btnNext"
                                        >
                                            {t("user.activity.reaction_speed.back")}
                                        </button>
                                    </Link>
                                }
                            </div>
                            <div className="col-md-6">
                                <div className="reaction-speed-p">
                                    <p className="reaction-speed-p-1">{t("user.activity.executive_function.intro_heading")}</p>
                                    <p className="reaction-speed-p-2" dangerouslySetInnerHTML={{ __html: t("user.activity.executive_function.index_message") }}></p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="reaction-speed-step">
                                    {/* <p>Score:{score}</p> */}
                                    <p>{step}/{maxSteps}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <>
                                {/* <div>
                                    Time Remaining: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                                </div> */}
                                {/* <button onClick={togglePause}>
                                    {isPaused ? 'Resume' : 'Pause'}
                                </button> */}
                                {/* <p>Score:{score}</p> */}
                                {/* <p>congruentAvgResponseTimes:{congruentAvgResponseTimes}</p>
                                <p>incongruentAvgResponseTimes:{incongruentAvgResponseTimes}</p>
                                <p>neutralAvgResponseTimes:{neutralAvgResponseTimes}</p> */}
                                {/* <p>Response Time:{time}</p> */}
                                {/* <p>accuracyCurrentRound:{accuracyCurrentRound}</p>
                                <p>avg Accuracy:{avgAccuracy}</p> */}
                                {/* <h4>{stepTimeRemaining}</h4> */}
                                <div className="exective-function-word">
                                    <div className="exective-function-display-word" style={{ color: wordColor, lineHeight: 10, padding: 20 }}>
                                        <h2>{t(`user.activity.executive_function.displayWord.${word}`)}</h2>
                                        {/* <h2>{word}</h2> */}
                                    </div>

                                    <div className="exective-function-color-word">
                                        {
                                            words.map((p, index) => {
                                                return (
                                                    <div className="exective-function-word-1" onClick={(e) => colorCheck(colors[index], wordColor, condition)}>
                                                        <div className="exective-function-word-1-1">
                                                            {/* <p style={{ color: colors[index], padding: 10 }}>{p}</p> */}
                                                            <p>{t(`user.activity.executive_function.displayWord.${p}`)}</p>
                                                            {/* <p>{p}</p> */}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )
                                        }
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{ width: `${stepTimeRemaining}%` }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </section>
                :
                // <ReactionSpeedResume props={{ "round": round, "totalTime": totalTime, "resetRound": resetRound, "togglePause": togglePause, "startTimer": startTimer, "stepTimeRemaining": stepTimeRemaining, "avgAccuracy": avgAccuracy, "params": props.match.params, "totalAvgResponseTime": totalAvgResponseTime }} />

                <ExecutiveFunctionResume props={{ "round": round, "totalTime": totalTime, "resetRound": resetRound, "togglePause": togglePause, "startTimer": startTimer, "timeRemaining": timeRemaining, "avgAccuracy": avgAccuracy, "params": props.match.params, "totalAvgResponseTime": totalAvgResponseTime, "totalAvgCongruentResponseTime": totalAvgCongruentResponseTime, "totalAvgIncongruentResponseTime": totalAvgIncongruentResponseTime, "totalAvgNeutralResponseTime": totalAvgNeutralResponseTime, 'free': free ,'maxSteps':maxSteps,'step':step}} />
            }
        </>
    )
}
