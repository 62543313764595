import { Suspense } from 'react';
import './App.css';
import "./assets/css/bootstrap.css"
import "./assets/css/bootstrap.min.css"
import "./assets/css/owl.theme.default.min.css"
import "./assets/css/owl.carousel.min.css"
import Confirm from './components/user/ui/confirm';
import Login from './components/user/ui/login';
import Steps from './components/user/steps';
import Dashboard from './components/user/dashboard';
import Forgotpassword from './components/user/ui/forgotpassword';
import Resetpassword from './components/user/ui/resetpassword';
import Updatepassword from './components/user/ui/updatepassword';
import Register from './components/user/ui/register';
import OTPlogin from './components/user/ui/otpLogin';
import ValidateMail from './components/user/ui/validateMail';
import Profile from './components/user/profile';
import Summary from './components/user/summary';
import Privacy from './components/static/privacy';
import Formazione from './components/static/formazione';
import Referements from './components/static/referements';
import Faq from './components/static/faq';
import Profilemenu from './components/user/profileMenu';
import AdminForgotpassword from './components/admin/ui/forgotpassword';
import AdminLogin from './components/admin/ui/login';
import Adminotp from './components/admin/ui/OTPlogin';
import Adminresetpassword from './components/admin/ui/resetpassword';
import Adminsteps from './components/admin/Steps';
import AdminDashboard from './components/admin/Dashboard';
import Sidebar from './components/admin/sidebar';
import AdmindsidebarProfile from './components/admin/sidebarProfile';
import Adminreport from './components/admin/report';
import Adminsummary from './components/admin/Summary';
import Adminprofile from './components/admin/profile';
import Adminfaq from './components/admin/faq';
import Adminreferements from './components/admin/referements';
import Adminprivacy from './components/admin/ui/static/privacy';
import Adminterms from './components/admin/ui/static/terms';
import ProtectedRoute from "./components/guards/protectedRoute"
import UnprotectedRoute from "./components/guards/unprotectedRoute"
import AdminProtectedRoute from './components/guards/adminprotectedroutes';
import AdminUnProtectedRoute from './components/guards/adminunprotectedroutes';
import SA_UnProtectedRoute from './components/guards/sa_unprotectedroute';
import SA_ProtectedRoute from './components/guards/sa_protectedroute';
import TermsCondition from './components/static/terms&cnd';
import Profile_Menu from './components/admin/ui/profilemenuAdmin';
import SuperadminLogin from './components/superAdmin/ui/login';
import SuperadminOtp from './components/superAdmin/ui/OTPlogin';
import Superadminforgotpassword from './components/superAdmin/ui/forgotpassword';
import Superadminresetpassword from './components/superAdmin/ui/resetpassword';
import Super_WhiteLabel from './components/superAdmin/ui/super_whiteLabel';
import User_Logs from './components/superAdmin/ui/user_logs';
import {
      BrowserRouter,
      Switch,
      Route,
      Redirect
} from "react-router-dom";
import Questionnaire from './components/user/questionnaire';
import PowerBiDemo from './components/admin/powerBiDemo';
import PBI from './components/admin/powerbi';
import Super_Protocol from './components/superAdmin/ui/super_protocol';
import Edit_Protocol from './components/superAdmin/ui/edit_protocol';
import SA_Profile from './components/superAdmin/ui/sa_profile';
import SA_Programs_Details from './components/superAdmin/ui/saProgramDetails';
import SA_Training from './components/superAdmin/ui/training';
import Edit_Training from './components/superAdmin/ui/edit_training';
import InvalidDynamicId from './components/common/invalidDynamicId';
import PageNotFound from './components/common/notFound';
import SA_Troubleshooting from './components/superAdmin/ui/troubleshooting';
import Admin_Programs_Details from './components/admin/ui/adminProgramDetails';
import { Brain_wellness } from './components/superAdmin/ui/brain_wellness';
import { TrainingCognitive } from './components/user/trainingCognitive';
import { MindFullNess } from './components/user/mindFullNess';
import { ReactionSpeedIntro } from './components/user/activity/ReactionSpeed/ReactionSpeedIntro';
import { ReactionSpeed } from './components/user/activity/ReactionSpeed/ReactionSpeed';
import { Edit_brainwellness } from './components/superAdmin/ui/edit_brainwellness';
import { PreAssessment } from './components/user/preAssessment';
import { ExecutiveFunctionIntro } from './components/user/activity/ExecutiveFunction/ExecutiveFunctionIntro';
import { ExecutiveFunctionIndex } from './components/user/activity/ExecutiveFunction/ExecutiveFunctionIndex';
import { PlanningIntro } from './components/user/activity/PlanningActivity/PlanningIntro';
import { Planning } from './components/user/activity/PlanningActivity/Planning';
import BrainwellnessScience from './components/static/brainwellnessScience';
import BrainwellnessScienceTermsCnd from './components/static/brainwellnessScienceTerms&cnd';
import BrainwellnessSciencePrivacyPolicy from './components/static/brainwellnessSciencePrivacyPolicy';
import BrainwellnessScienceCookiePolicy from './components/static/brainwellnessScienceCookiePolicy';
import ReferementScience from './components/static/ReferementScience';
import { WorkingMemoryIntro } from './components/user/activity/WorkingMemory/WorkingMemoryIntro';
import { WorkingMemory } from './components/user/activity/WorkingMemory/WorkingMemory';
import { GoNoGoIntro } from './components/user/activity/GoNoGo/GoNoGoIntro';
import { GoNoGoMasterShape } from './components/user/activity/GoNoGo/GoNoGoMasterShape';
import { GoNoGoSimpleIntro } from './components/user/activity/GoNoGoSimple/GoNoGoSimpleIntro';
import { GoNoGoSimpleMasterShape } from './components/user/activity/GoNoGoSimple/GoNoGoSimpleMasterShape';

import { NBackIntro } from './components/user/activity/NBackActivity/NBackIntro';
import { NBackInfo } from './components/user/activity/NBackActivity/NBackInfo';
import { WorkingMemoryResult } from './components/user/activity/WorkingMemory/WorkingMemoryResult';
import { WorkingMemoryResume } from './components/user/activity/WorkingMemory/WorkingMemoryResume';

import { NBackVisivoIntro } from './components/user/activity/NBackVisivo/NBackVisivoIntro';
import { NBackVisivoIndex } from './components/user/activity/NBackVisivo/NBackVisivoIndex';

import { NBackAcousticIntro } from './components/user/activity/NBackAcoustic/NBackAcousticIntro';
import { NBackAcousticIndex } from './components/user/activity/NBackAcoustic/NBackAcousticIndex';

import { NBackMovementIntro } from './components/user/activity/NBackMovement/NBackMovementIntro';
import { NBackMovementIndex } from './components/user/activity/NBackMovement/NBackMovementIndex';

import { NBackVisuoAcousticIntro } from './components/user/activity/NBackVisuoAcoustic/NBackVisuoAcousticIntro';
import { NBackVisuoAcousticIndex } from './components/user/activity/NBackVisuoAcoustic/NBackVisuoAcousticIndex';
import MindfulnessReport from './components/user/ActivityReports/MindFulnessReport/MindfulnessReport';
import AdminTraining from './components/admin/adminTraining';
import UserTraining from './components/user/ui/userTraining';
import UserProgramDetails from './components/user/ui/programDetails';
import anxietyAndDepression from './components/user/anxietyAndDepression';
import addProtocol from './components/superAdmin/ui/add_protocol';
import GoNoGoComplexIntro from './components/user/activity/GoNoGoComplex/GoNoGoComplexIntro';
import GoNoGoMasterShapeComplex from './components/user/activity/GoNoGoComplex/GoNoGoMasterShapeComplex';
import CognitiveReport from './components/user/ActivityReports/CognitiveReport/CognitiveReport';
import UserManual from './components/user/userManual';
import AdminManual from './components/admin/ui/adminManual';
import SadminManual from './components/superAdmin/ui/sadminManual';
import CommonDashboard from './components/user/commonDashboard';
import { CommonBrainWellness } from './components/user/commonBrainwellness';
import ThankYou from './components/common/thankYou';

function App() {
      let path = (window.location.pathname).split('/')
      return (
            <Suspense fallback="loading">
                  <div className="App">
                        <div className="body-content">
                              {path[1] == 'app' ?
                                    <BrowserRouter basename="app/">
                                          <Switch>
                                                <Route exact path="/register/:company_id" component={Register} render={(props) => (<>
                                                      <Register  {...props} />
                                                </>)}>
                                                </Route>
                                                <Route exact path="/invalid" component={InvalidDynamicId} render={(props) => (<>
                                                      <InvalidDynamicId  {...props} />
                                                </>)}>
                                                </Route>
                                                <UnprotectedRoute exact path="/login/:company_id" component={Login} render={(props) => (<>
                                                      <Login  {...props} />
                                                </>)}>
                                                </UnprotectedRoute>
                                                <ProtectedRoute exact path="/steps" component={Steps} render={(props) => (<>
                                                      <Steps  {...props} />
                                                </>)}>
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path="/user-manual" component={UserManual} render={(props) => (<>
                                                      <UserManual />
                                                </>)}>
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path="/dashboard/:errorfetch?" component={Dashboard} render={(props) => (<>
                                                      <Dashboard  {...props} />
                                                </>)}>
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path="/common-dashboard" component={CommonDashboard} render={(props) => (<>
                                                      <CommonDashboard  {...props} />
                                                </>)}>
                                                </ProtectedRoute>

                                                {path[2] == 'congnitive-training' ? <Redirect exact from="/congnitive-training" to="/congnitive-training/1" /> : ''}
                                                <ProtectedRoute exact path="/congnitive-training/:repeatTime/:daySequence?/:componentId?" component={TrainingCognitive} render={(props) => (<>
                                                      <TrainingCognitive  {...props} />
                                                </>)}></ProtectedRoute>


                                                {path[2] == 'mindfulness' ? <Redirect exact from="/mindfulness" to="/mindfulness/1" /> : ''}

                                                <ProtectedRoute exact path="/mindfulness/:repeatTime/:daySequence?/:componentId?" component={MindFullNess} render={(props) => (<>
                                                      <MindFullNess  {...props} />
                                                </>)}></ProtectedRoute>

                                                
                                                {path[2] == 'common-brainwellness' ? <Redirect exact from="/common-brainwellness" to="/common-brainwellness/:id" /> : ''}
                                                <ProtectedRoute exact path="/common-brainwellness/:repeatTime/:daySequence?/:componentId?" component={CommonBrainWellness} render={(props) => (<>
                                                      <CommonBrainWellness  {...props} />
                                                </>)}></ProtectedRoute>
                                                

                                                <ProtectedRoute exact path="/reaction-speed-intro/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={ReactionSpeedIntro} render={(props) => (<>
                                                      <ReactionSpeedIntro  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/reaction-speed/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={ReactionSpeed} render={(props) => (<>
                                                      <ReactionSpeed  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/executive-function-intro/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={ExecutiveFunctionIntro} render={(props) => (<>
                                                      <ExecutiveFunctionIntro  {...props} />
                                                </>)}></ProtectedRoute>
                                                <ProtectedRoute exact path="/executive-function/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={ExecutiveFunctionIndex} render={(props) => (<>
                                                      <ExecutiveFunctionIndex  {...props} />
                                                </>)}></ProtectedRoute>
                                                <ProtectedRoute exact path="/planning-intro/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={PlanningIntro} render={(props) => (<>
                                                      <PlanningIntro  {...props} />
                                                </>)}></ProtectedRoute>
                                                <ProtectedRoute exact path="/planning/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={Planning} render={(props) => (<>
                                                      <Planning  {...props} />
                                                </>)}></ProtectedRoute>
                                                <ProtectedRoute exact path="/working-memory-intro/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={WorkingMemoryIntro} render={(props) => (<>
                                                      <WorkingMemoryIntro  {...props} />
                                                </>)}></ProtectedRoute>
                                                <ProtectedRoute exact path="/working-memory/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={WorkingMemory} render={(props) => (<>
                                                      <WorkingMemory  {...props} />
                                                </>)}></ProtectedRoute>
                                                <ProtectedRoute exact path="/go-no-go-intro/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={GoNoGoIntro} render={(props) => (<>
                                                      <GoNoGoIntro  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/n-back-intro/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={NBackIntro} render={(props) => (<>
                                                      <NBackIntro  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/n-back/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={NBackInfo} render={(props) => (<>
                                                      <NBackInfo  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/working-resume/:component_id/:repeat_time/:day_sequence/:activity_id" component={WorkingMemoryResume} render={(props) => (<>
                                                      <WorkingMemoryResume  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/working-result/:component_id/:repeat_time/:day_sequence/:activity_id" component={WorkingMemoryResult} render={(props) => (<>
                                                      <WorkingMemoryResult  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/working-resume/:component_id/:repeat_time/:day_sequence/:activity_id" component={WorkingMemoryResume} render={(props) => (<>
                                                      <WorkingMemoryResume  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/working-result/:component_id/:repeat_time/:day_sequence/:activity_id" component={WorkingMemoryResult} render={(props) => (<>
                                                      <WorkingMemoryResult  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/go-no-go/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={GoNoGoMasterShape} render={(props) => (<>
                                                      <GoNoGoMasterShape  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/go-no-go-complex-intro/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={GoNoGoComplexIntro} render={(props) => (<>
                                                      <GoNoGoComplexIntro  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/go-no-go-complex/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={GoNoGoMasterShapeComplex} render={(props) => (<>
                                                      <GoNoGoMasterShapeComplex  {...props} />
                                                </>)}></ProtectedRoute>


                                                <ProtectedRoute exact path="/go-no-go-simple-intro/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={GoNoGoSimpleIntro} render={(props) => (<>
                                                      <GoNoGoSimpleIntro  {...props} />
                                                </>)}></ProtectedRoute>
                                                <ProtectedRoute exact path="/go-no-go-simple/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={GoNoGoSimpleMasterShape} render={(props) => (<>
                                                      <GoNoGoSimpleMasterShape  {...props} />
                                                </>)}></ProtectedRoute>
                                                <ProtectedRoute exact path="/n-back-visivo-intro/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={NBackVisivoIntro} render={(props) => (<>
                                                      <NBackVisivoIntro  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/n-back-visivo/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={NBackVisivoIndex} render={(props) => (<>
                                                      <NBackVisivoIndex  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/n-back-acoustic-intro/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={NBackAcousticIntro} render={(props) => (<>
                                                      <NBackAcousticIntro  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/n-back-acoustic/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={NBackAcousticIndex} render={(props) => (<>
                                                      <NBackAcousticIndex  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/n-back-movement-intro/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={NBackMovementIntro} render={(props) => (<>
                                                      < NBackMovementIntro  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/n-back-movement/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={NBackMovementIndex} render={(props) => (<>
                                                      <NBackMovementIndex  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/n-back-visual-acoustic-intro/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={NBackVisuoAcousticIntro} render={(props) => (<>
                                                      <NBackVisuoAcousticIntro  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/n-back-visual-acoustic/:component_id/:repeat_time/:day_sequence/:activity_id/:free?" component={NBackVisuoAcousticIndex} render={(props) => (<>
                                                      <NBackVisuoAcousticIndex  {...props} />
                                                </>)}></ProtectedRoute>


                                                <ProtectedRoute exact path="/report-mindfulness/:component_id/:repeatTime" component={MindfulnessReport} render={(props) => (<>
                                                      <MindfulnessReport  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/report-cognitive/:component_id/:repeatTime" component={CognitiveReport} render={(props) => (<>
                                                      <CognitiveReport  {...props} />
                                                </>)}></ProtectedRoute>

                                                <ProtectedRoute exact path="/updatepassword" component={Updatepassword} render={(props) => (<>
                                                      <Updatepassword  {...props} />
                                                </>)}></ProtectedRoute>

                                                <Route exact path="/forget-password" render={(props) => (<>
                                                      <Forgotpassword  {...props} />
                                                </>)}>
                                                </Route>
                                                <Route exact path="/resetpassword/:token" render={(props) => (<>
                                                      <Resetpassword  {...props} />
                                                </>)}>
                                                </Route>
                                               
                                                <Route path="/otp/:otp?/:email?" render={(props) => (<>
                                                      <OTPlogin  {...props} />
                                                </>)}>
                                                </Route>
                                                <ProtectedRoute exact path="/questionnare/:errorfetch?" component={Questionnaire} render={(props) => (<>
                                                      <Questionnaire  {...props} />
                                                </>)}>
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path="/validateemail" component={ValidateMail} render={(props) => (<>
                                                      <ValidateMail  {...props} />
                                                </>)}>
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path="/profile" component={Profile} render={(props) => (<>
                                                      <Profile  {...props} />
                                                </>)}>
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path="/summary" component={Summary} render={(props) => (<>
                                                      <Summary  {...props} />
                                                </>)}>
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path="/privacy" component={Privacy} render={(props) => (<>
                                                      <Privacy  {...props} />
                                                </>)}>
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path="/terms" component={TermsCondition} render={(props) => (<>
                                                      <TermsCondition  {...props} />
                                                </>)}>
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path="/faq" component={Faq} render={(props) => (<>
                                                      <Faq  {...props} />
                                                </>)}>
                                                </ProtectedRoute>

                                                <ProtectedRoute exact path="/referement-science" component={ReferementScience} render={(props) => (<>
                                                      <ReferementScience  {...props} />
                                                </>)}>
                                                </ProtectedRoute>

                                                <ProtectedRoute exact path="/brainwellness-science" component={BrainwellnessScience} render={(props) => (<>
                                                      <BrainwellnessScience  {...props} />
                                                </>)}>
                                                </ProtectedRoute>

                                                <ProtectedRoute exact path="/brainwellness-science-terms-conditions" component={BrainwellnessScienceTermsCnd} render={(props) => (<>
                                                      <BrainwellnessScienceTermsCnd  {...props} />
                                                </>)}>
                                                </ProtectedRoute>

                                                <ProtectedRoute exact path="/brainwellness-science-privacy-policy" component={BrainwellnessSciencePrivacyPolicy} render={(props) => (<>
                                                      <BrainwellnessSciencePrivacyPolicy  {...props} />
                                                </>)}>
                                                </ProtectedRoute>

                                                <ProtectedRoute exact path="/brainwellness-science-cookie-policy" component={BrainwellnessScienceCookiePolicy} render={(props) => (<>
                                                      <BrainwellnessScienceCookiePolicy  {...props} />
                                                </>)}>
                                                </ProtectedRoute>


                                                <ProtectedRoute exact path="/profilemenu" component={Profilemenu} render={(props) => (<>
                                                      <Profilemenu  {...props} />
                                                </>)}>
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path="/referements" component={Referements} render={(props) => (<>
                                                      <Referements  {...props} />
                                                </>)}>
                                                </ProtectedRoute>

                                                <ProtectedRoute exact path="/formazione" render={(props) => (<>
                                                      <Formazione  {...props} />
                                                </>)}>
                                                </ProtectedRoute>

                                                <ProtectedRoute exact path="/preassessment/:component_id/:repeat_time/:day_sequence/:activity_id" component={PreAssessment} render={(props) => (<>
                                                      <PreAssessment  {...props} />
                                                </>)}>
                                                </ProtectedRoute>

                                                <ProtectedRoute exact path="/training" component={UserTraining} render={(props) => (<>
                                                      <UserTraining {...props} />
                                                </>)}>
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path="/program-details/:programId" component={UserProgramDetails} render={(props) => (<>
                                                      <UserProgramDetails {...props} />
                                                </>)}>
                                                </ProtectedRoute>
                                                <ProtectedRoute exact path="/anxiety-and-depression/:type/:set" component={anxietyAndDepression} render={(props) => (<>
                                                      <UserProgramDetails {...props} />
                                                </>)}>
                                                </ProtectedRoute>
                                                <Route path="*" component={PageNotFound} />
                                          </Switch>
                                    </BrowserRouter>
                                    :
                                    path[1] == 'admin' ?
                                          <BrowserRouter basename="admin/">
                                                <Switch>
                                                      <Route exact path="/power-bi" render={(props) => (<>
                                                            <PowerBiDemo  {...props} />
                                                      </>)}>
                                                      </Route>
                                                      <Route exact path="/pbi" render={(props) => (<>
                                                            <PBI  {...props} />
                                                      </>)}>
                                                      </Route>
                                                      <AdminUnProtectedRoute exact path="/signin/:company_id" component={AdminLogin} render={(props) => (<>
                                                            <AdminLogin  {...props} />
                                                      </>)}>
                                                      </AdminUnProtectedRoute>
                                                      <Route exact path="/invalid" component={InvalidDynamicId} render={(props) => (<>
                                                            <InvalidDynamicId  {...props} />
                                                      </>)}>
                                                      </Route>
                                                      <Route exact path="/passforgot" render={(props) => (<>
                                                            <AdminForgotpassword  {...props} />
                                                      </>)}>
                                                      </Route>
                                                      <Route exact path="/passwordreset/:token" render={(props) => (<>
                                                            <Adminresetpassword  {...props} />
                                                      </>)}>
                                                      </Route>

                                                      <Route exact path="/bi-otp/:otp?/:email?" render={(props) => (<>
                                                            <Adminotp  {...props} />
                                                      </>)}>
                                                      </Route>
                                                      <AdminProtectedRoute exact path="/pages/:page_name?" component={AdminDashboard} render={(props) => (<>
                                                            <AdminDashboard  {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/my-sidebar" component={Sidebar} render={(props) => (<>
                                                            <Sidebar {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/admin-manual" component={AdminManual} render={(props) => (<>
                                                            <AdminManual />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/profilesidebar" component={AdmindsidebarProfile} render={(props) => (<>
                                                            <Admindside

                                                                  barProfile {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/bi-report" component={Adminreport} render={(props) => (<>
                                                            <Adminreport {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/bi-summary" component={Adminsummary} render={(props) => (<>
                                                            <Adminsummary {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/my-profile" component={Adminprofile} render={(props) => (<>
                                                            <Adminprofile {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/frequently-asked-questions" component={Adminfaq} render={(props) => (<>
                                                            <Adminfaq {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/scientific-references" component={Adminreferements} render={(props) => (<>
                                                            <Adminreferements {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/bi-privacy" component={Adminprivacy} render={(props) => (<>
                                                            <Adminprivacy {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/bi-terms" component={Adminterms} render={(props) => (<>
                                                            <Adminterms {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/bi-steps" component={Adminsteps} render={(props) => (<>
                                                            <Adminsteps {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/profile-menu" component={Profile_Menu} render={(props) => (<>
                                                            <Profile_Menu {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/training/:company_id/:program_id" component={Admin_Programs_Details} render={(props) => (<>
                                                            < Admin_Programs_Details  {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>
                                                      <AdminProtectedRoute exact path="/training" component={AdminTraining} render={(props) => (<>
                                                            <Profile_Menu {...props} />
                                                      </>)}>
                                                      </AdminProtectedRoute>

                                                      <Route path="*" component={PageNotFound} />

                                                </Switch>
                                          </BrowserRouter>
                                          :
                                          path[1] == 'sa' ?
                                                <BrowserRouter basename="sa/">
                                                      <Switch>
                                                            <SA_UnProtectedRoute exact path="/signin" component={SuperadminLogin} render={(props) => (<>
                                                                  <SuperadminLogin  {...props} />
                                                            </>)}>
                                                            </SA_UnProtectedRoute>

                                                            <Route exact path="/otp-verification" component={SuperadminOtp} render={(props) => (<>
                                                                  <SuperadminOtp  {...props} />
                                                            </>)}>
                                                            </Route>

                                                            <Route exact path="/forgot-password" component={Superadminforgotpassword} render={(props) => (<>
                                                                  <Superadminforgotpassword  {...props} />
                                                            </>)}>
                                                            </Route>

                                                            <Route path="/resetpassword/:token" component={Superadminresetpassword} render={(props) => (<>
                                                                  < Superadminresetpassword  {...props} />
                                                            </>)}>
                                                            </Route>

                                                            <SA_ProtectedRoute exact path="/protocol" component={Super_Protocol} render={(props) => (<>
                                                                  < Super_Protocol  {...props} />
                                                            </>)}></SA_ProtectedRoute>
                                                            <SA_ProtectedRoute exact path="/protocol/:company_id/:id" component={Edit_Protocol} render={(props) => (<>
                                                                  < Edit_Protocol  {...props} />
                                                            </>)}></SA_ProtectedRoute>
                                                            <SA_ProtectedRoute exact path="/profile" component={SA_Profile} render={(props) => (<>
                                                                  < SA_Profile  {...props} />
                                                            </>)}></SA_ProtectedRoute>

                                                            <SA_ProtectedRoute exact path="/programs-details" component={SA_Programs_Details} render={(props) => (<>
                                                                  < SA_Programs_Details  {...props} />
                                                            </>)}></SA_ProtectedRoute>
                                                            <SA_ProtectedRoute exact path="/training" component={SA_Training} render={(props) => (<>
                                                                  < SA_Training  {...props} />
                                                            </>)}></SA_ProtectedRoute>
                                                            <SA_ProtectedRoute exact path="/training/:company_id" component={Edit_Training} render={(props) => (<>
                                                                  < Edit_Training  {...props} />
                                                            </>)}></SA_ProtectedRoute>
                                                            <SA_ProtectedRoute exact path="/brain-wellness" component={Brain_wellness} render={(props) => (<>
                                                                  <Brain_wellness  {...props} />
                                                            </>)}></SA_ProtectedRoute>
                                                            <SA_ProtectedRoute exact path="/brain-wellness/:company_id/:company_name" component={Edit_brainwellness} render={(props) => (<>
                                                                  < Edit_brainwellness  {...props} />
                                                            </>)}></SA_ProtectedRoute>
                                                            <SA_ProtectedRoute exact path="/sadmin-manual" component={SadminManual} render={(props) => (<>
                                                                  <SadminManual />
                                                            </>)}>
                                                            </SA_ProtectedRoute>
                                                            <SA_ProtectedRoute exact path="/training/:company_id/:program_id" component={SA_Programs_Details} render={(props) => (<>
                                                                  < SA_Programs_Details  {...props} />
                                                            </>)}></SA_ProtectedRoute>

                                                            <SA_ProtectedRoute exact path="/troubleshooting/:company" component={SA_Troubleshooting} render={(props) => (<>
                                                                  < SA_Troubleshooting  {...props} />
                                                            </>)}></SA_ProtectedRoute>
                                                            <SA_ProtectedRoute exact path="/add-protocal" component={addProtocol} render={(props) => (<>
                                                                  < SA_Troubleshooting  {...props} />
                                                            </>)}></SA_ProtectedRoute>
                                                            <SA_ProtectedRoute exact path="/white-label" component={Super_WhiteLabel} render={(props) => (<>
                                                            </>)}></SA_ProtectedRoute>
                                                            <SA_ProtectedRoute exact path="/userLog/:id" component={User_Logs} render={(props) => (<>
                                                            </>)}></SA_ProtectedRoute>
                                                            <Route path="*" component={PageNotFound} />
                                                      </Switch>
                                                </BrowserRouter>
                                                :
                                                <BrowserRouter basename="/">
                                                      <Switch>
                                                            <Route exact path="/confirm/:token/:company_id" render={(props) => (<>
                                                                  <Confirm  {...props} />
                                                            </>)}>
                                                            </Route>
                                                            <Route exact path="/thankyou" component={ThankYou} />
                                                            <Route path="*" component={PageNotFound} />
                                                      </Switch>
                                                </BrowserRouter>
                                                
                              }
                        </div>
                  </div>
            </Suspense>
      );
}

export default App;