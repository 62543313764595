import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import Group1432 from "../../../../assets/Image/Group_1432.svg";
import { getLocalStoarge, setLocalStoarge } from '../../../common/allfunction';
import PostRequest from '../../../apiConnection/postRequest';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const PlanningResult = ({ props }) => {
    const { params, avgAccuracy, totalTime, totalAvgResponseTime, score ,free } = props;
    console.log("props", props);

    const [t, i18n] = useTranslation("common");
    const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
    const [isLoading, setIsLoading] = useState(true);

    const [payload, setPayload] = useState({
        company_id: companyId,
        component_id: parseInt(params.component_id),
        day_sequence: parseInt(params.day_sequence),
        repeat_time: parseInt(params.repeat_time),
        activity_id: parseInt(params.activity_id),
        status: "complete",
        activity_score: score,
        activity_play_time: 0,
        total_avg_response_time: 0
        // activity_round_no: round + 1
    })

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    useEffect(() => {
    setPayload((prevPayload) => ({
    ...prevPayload,
    activity_score: avgAccuracy, // Update activity_score with the new avgAccuracy
    }));
    }, [avgAccuracy]);

    useEffect(() => {
        console.log("payload", payload);
        if (free) {
            setLocalStoarge("avgAccuracy", avgAccuracy);
            setLocalStoarge("totalAvgResponseTime", totalAvgResponseTime);
            var resultObj = [];
            if (localStorage.getItem('cognativeResult_'+payload.repeat_time).length > 0) {
                resultObj = JSON.parse(localStorage.getItem('cognativeResult_'+payload.repeat_time));
            }
            resultObj[2] = {
                'activityName': 2,
                'avgAccuracy': ((score*100)/36)/100,
                'totalAvgResponseTime': totalAvgResponseTime
            };
            setLocalStoarge("cognativeResult_"+payload.repeat_time, JSON.stringify(resultObj));
            setIsLoading(false)
        }
        else {
            submitUserActivityData(payload);
        }
    }, [payload])


    const submitUserActivityData = async (payload_1) => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );

            console.log("refresh_token", refreshToken);
            if (refreshToken) {
                const result = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBMIT_USER_ACTIVITY_DATA}`,
                    payload_1,
                    "",
                    ""
                );
                console.log("api_submit_result", result);
                if (result && result.status === 200) {
                    console.log("api_submit_result_1", result);
                    setIsLoading(false);

                } else if (result && result.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }


    return (
        <section className="reaction-speed-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="stepsin stpelog">
                            <Link to="dashboard">
                                <img src={logo} className="logo-steps" alt="logo" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="reaction-speed-result">
                            <div className="col-md-6">
                                <div className="reaction-speed-result-1">
                                    <h2>{t("user.activity.reaction_speed.result_heading")}</h2>
                                    <p className="" dangerouslySetInnerHTML={{ __html: t('user.activity.planning_activty.result_message').replace('dynamicScore', score) }}></p>
                                    <div className="row">
                                        <div className="reaction-speed-result-1-1">
                                            <div className="col-md-6">
                                                <p className="reaction-speed-result-reponse-bar">
                                                    <CircularProgressbar value={score} maxValue={36} strokeWidth={12} styles={buildStyles({
                                                        pathColor: parseInt(score) >= 20 ? '#9ECB61' : '#B02418',
                                                    })}
                                                    /></p>
                                            </div>
                                            <div className="col-md-6 planning-res reaction-speed-result-accuracy">
                                                <p><span className="planning-res-outcome" >{score}</span><span className="planning-res-total">/36</span><span className="planning-point">{t('user.activity.planning_activty.result')}</span></p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 reaction-speed-result-2">
                                {/* <div className=""> */}
                                <img src={Group1432} alt="" />
                                {/* </div> */}
                            </div>
                        </div>
                        <Link to={`/congnitive-training/${parseInt(params.repeat_time)}`}>
                            <button className="btn btn-primary btnNext-act">
                                {t("user.activity.reaction_speed.exit")}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )

}
